import ApiInstance from './ApiInstance.js'

export default {
  
  getIndustries() {
    return ApiInstance({ requiresAuth: true }).get('/industry');
  },
  addNewIndustry(Data) {
    return ApiInstance({ requiresAuth: true }).post('/industry', Data);
  },
  editIndustry(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/industry/${id}`, Data);
  },
  deleteIndustry(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/industry/${id}`);
  }
}
